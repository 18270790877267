import React, { useEffect, useRef, useState, useMemo } from "react";
import { Button, Descriptions, Form, Input, Modal, Space } from "antd";
import "./SketchfabModel.css";
import dayjs from "dayjs";
import {
  getMachineParameterLog,
  updateMachineParameterLog,
} from "../../../api/db/main";
import anh1 from "../../../assets/images/anh2.jpg";
import anh2 from "../../../assets/images/anh1.jpg";

const SketchFab = ({ setIsShow3D, setIsShowTable }) => {
  const [client, setClient] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenFirstModal, setIsOpenFirstModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [dataLog, setDataLog] = useState();
  const [showAnh1, setShowAnh1] = useState('none');
  const [showAnh2, setShowAnh2] = useState('none');
  const models = useRef({
    cnc: [],
    quality: [],
    logistic: [],
    monior: [],
    machine: [],
  });
  const [form] = Form.useForm();
  const handleClose3DView = () => {
    setIsShow3D("none");
    setIsShowTable("block");
  };

  const closePopup = () => {
    setIsOpenModal(false);
    setIsOpenFirstModal(false);
    setSelectedNode(null);
  };

  const showPopupWarning = (status = true) => {
    if (client) {
      client.getNodeMap(async (err, nodes) => {
        if (!err) {
          const hideModels = [
            ...models.current.logistic,
            ...models.current.machine,
            ...models.current.monior,
            ...models.current.quality,
          ];
          const instanceIdsToHide = hideModels.map(
            (models) => models.instanceID
          );
          instanceIdsToHide.forEach((id) => {
            const node = nodes[id];
            if (node) {
              setSelectedNode(node);
              if (status) client.show(node.instanceID);
              else client.hide(node.instanceID);
            }
          });
        }
      });
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      moveCameraToNode(client);
    }, 2000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    setIsShow3D("flex");
    setIsShowTable("none");
  }, [isOpenFirstModal, isOpenModal]);
  const moveCameraToNode = async (api) => {
    const res = await getMachineParameterLog();
    if (res?.data) {
      setDataLog(res.data);
      const monitor = res.data?.monitor;
      if (!monitor) {
        setIsOpenModal(false);
        closePopup();
        handleClose3DView();
      }
      if (Number(monitor?.status) === 0) {
        setShowAnh1('block');
        setShowAnh2('none');
        showPopupWarning(true);
        setIsOpenFirstModal(true);
      } else if (Number(monitor?.status) === 1) {
        setShowAnh1('none');
        setShowAnh2('block');
        if (monitor?.troubleshoot) {
          showPopupWarning(false);
          setIsOpenModal(false);
          setIsOpenFirstModal(false);
        } else {
          setIsOpenFirstModal(false);
          showPopupWarning(false);
          setIsOpenModal(true);
        }
      }
    } else {
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      moveCameraToNode(client);
    }, 1500);
    return () => clearInterval(interval);
  }, []);
  const onFinish = async (values) => {
    if (values) {
      const res = await updateMachineParameterLog({
        parameter_id: dataLog?.monitor?.parameter_id,
        troubleshoot: values?.troubleshoot,
      });
      if (res.success) {
        form.resetFields();
        closePopup();
        if (client) {
          if (warning) moveCameraToNode(client);
        }
      }
    }
  };

  const itemsDescription = [
    {
      key: "start_time",
      label: "Thời gian bắt đầu",
      children: dataLog?.monitor?.created_at
        ? dayjs(dataLog?.monitor?.created_at).format("DD/MM/YYYY HH:mm:ss")
        : dayjs().format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      key: "end_time",
      label: "Thời gian kết thúc",
      children: dataLog?.monitor?.updated_at
        ? dayjs(dataLog?.monitor?.updated_at).format("DD/MM/YYYY HH:mm:ss")
        : dayjs().format("DD/MM/YYYY HH:mm:ss"),
    },

    {
      key: "content",
      label: "Hướng dẫn xử lý",
      span: 2,
      children: (
        <div>
          <div>[Machine] Kiểm tra đường dẫn ký nén bị lỏng</div>
          <div>[Machine] Kiểm tra van cấp khí nén bị lỏng</div>
          <div>[Machine] Kiểm tra máy cấp khí có hoạt động hay không</div>
        </div>
      ),
    },
    {
      key: "troubleshoot",
      label: "Kết quả xử lý",
      span: 2,
      children: (
        <>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="troubleshoot"
            rules={[{ required: true, message: "Vui lòng nhập!" }]}
          >
            <Input.TextArea placeholder="Vui lòng nhập" rows={2} />
          </Form.Item>
        </>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Giám sát bất thường",
      span: 2,
      children: (
        <ul>
          <li>Hiện trạng: Áp suất giảm liên tục 3 lần liên tiếp</li>
          <li>
            Mức ảnh hưởng: Dự báo áp suất có thể vượt giới hạn kiểm soát, gây
            lỗi chất lượng
          </li>
        </ul>
      ),
    },
    {
      key: "2",
      label: "Hướng dẫn xử lý",
      span: 2,
      children: (
        <ul>
          <li>[Machine] Kiểm tra đường dẫn ký nén bị lỏng</li>
          <li>[Machine] Kiểm tra van cấp khí nén bị lỏng</li>
          <li>[Machine] Kiểm tra máy cấp khí có hoạt động hay không</li>
        </ul>
      ),
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <img
        src={anh1}
        alt="anh1"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          display: showAnh1,
        }}
      />
      <img
        src={anh2}
        alt="anh2"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          display: showAnh2,
        }}
      />
      <Modal
        className="modal-simulator"
        title={
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            [Kết quả xử lý]
          </div>
        }
        open={isOpenModal}
        onCancel={closePopup}
        footer={null}
        width={1000}
        style={{ top: 70 }}
        mask={false}
      >
        <Form
          style={{ padding: "5px 10px" }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Descriptions
            size="small"
            layout="horizontal"
            bordered
            labelStyle={{
              color: "black",
              fontSize: "18px",
              fontWeight: "bold",
              width: "200px",
            }}
            contentStyle={{ color: "black", fontSize: "18px" }}
            items={itemsDescription}
            column={2}
          />
          <Form.Item
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            <Space>
              <Button type="primary" htmlType="submit">
                Xác nhận
              </Button>
              <Button htmlType="button" onClick={closePopup}>
                Hủy
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        className="modal-simulator"
        title={
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              background: "#2462a3",
            }}
          >
            [LH1-A2] Áp suất khí nén giảm (
            {dayjs(dataLog?.updated_at).format("DD/MM/YYYY HH:mm:ss")})
          </div>
        }
        open={isOpenFirstModal}
        onCancel={closePopup}
        footer={null}
        width={1000}
        style={{ top: 70 }}
        mask={false}
      >
        <div style={{ padding: "5px 10px" }}>
          <Descriptions
            size="small"
            layout="horizontal"
            bordered
            labelStyle={{
              color: "black",
              fontSize: "18px",
              fontWeight: "bold",
              width: "210px",
            }}
            contentStyle={{ color: "black", fontSize: "18px" }}
            items={items}
            column={2}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SketchFab;
