import { Card } from "antd";
import Highcharts from "highcharts";
import { useEffect } from "react";

const PQCDetailChart = ({ data = [] }) => {
    useEffect(() => {
        // if (data.length === 0) {
        //     return;
        // }
        const series = [
            {
                name: 'Số lỗi',
                colorByPoint: true,
                data: data.map(e=>({...e, y: e.value, name: e.error_name}))
            }
        ]
        const options = {
            chart: {
                type: 'pie',
                height: 200,
            },
            title: false,
            series: series,
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: [{
                        enabled: true,
                        distance: 5
                    }, {
                        enabled: false,
                    }]
                }
            },
            exporting: false,
            credits: { enabled: false }
        };
        Highcharts.chart("detail-error-chart", options);
    }, [data]);
    return (
        // <Card title="Tỷ lệ lỗi" style={{ height: '100%', padding: '0px' }} styles={{ body: { padding: 12 } }}>
            <div id={"detail-error-chart"} />
        // </Card>
    )
}

export default PQCDetailChart;