import axios from "axios";
const prefix = 'p2/ui/';
export async function getTreeSelect(params) {
    const res = await axios.get(prefix+'tree-select', {params});
    return res;
}
export async function getProductionHistory(params) {
    const res = await axios.get(prefix+'production-history', {params});
    return res;
}
export async function exportProductionHistory(params) {
    const res = await axios.get(prefix+'production-history/export', {params});
    return res;
}
export async function exportReportProductionHistory(params) {
    const res = await axios.get(prefix+'production-history/export-report', {params});
    return res;
}