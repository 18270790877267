import { Modal, Button, Card, Col, DatePicker, Divider, Form, Input, Layout, Radio, Row, Select, AutoComplete, Table, Tabs, Typography, Space, Spin, Checkbox, message, Tree } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState, useEffect } from "react";
import { Pie, Column, Line } from '@ant-design/plots';
import DataDetail from "../../../components/DataDetail";
import {
    getErrors, getLines, getMachineOfLine, getCustomers,
    getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getPQCHistory, getDataFilterUI, getDetailDataError, errorData,
    getTreeLines
} from '../../../api/ui/main';
import dayjs from "dayjs";
import CalculateTableHeight from "../../../components/calculateTableHeight";
import { baseURL } from '../../../config';
import { LoadingOutlined } from "@ant-design/icons";
import { getTreeSelect } from "../../../api/phase2/ui/main";
import { exportPQCReport, exportQualittyDataTable, exportTestCriteriaHistory, getQualittyDataChart, getQualityDataDetail, getQualityDataTable } from "../../../api/phase2/ui/quality";
import ErrorTrending from "./chart/ErrorTrending";
import MaterialErrorRatio from "./chart/MaterialErrorRatio";
import ErrorRatio from "./chart/ErrorRatio";
import PQCDetailChart from "./chart/PQCDetailChart";

const QualityPQC = (props) => {
    document.title = "UI - PQC";
    const [listLines, setListLines] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        page: 1,
        pageSize: 10,
        date: [dayjs(), dayjs()]
    });
    useEffect(() => {
        (async () => {
            const res1 = await getTreeSelect();
            setListLines(res1.data);
            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
        })()
        // btn_click()
    }, [])

    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                setListLoSX(Object.values(res.data.lo_sx).map(e => {
                    return { label: e, value: e }
                }));
            }
        })()
    }, [params.khach_hang])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setDataDetailError([]);
    };
    const [dataTable2, setDataTable2] = useState();

    async function openDetailQC(record) {
        var res = await getQualityDataDetail({ lot_id: record?.lot_id, cong_doan: record?.cong_doan, machine_code: record?.machine_code, });
        if (res.success) {
            setDataDetailError(res.data);
            showModal();
        }
    }

    const columns2 = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
            fixed: 'left',
            width: 60,
        },
        {
            title: 'Ngày PQC kiểm tra',
            dataIndex: 'thoi_gian_kiem_tra',
            key: 'thoi_gian_kiem_tra',
            align: 'center',
            fixed: 'left',
            width: 150,
        },
        {
            title: 'Ca',
            dataIndex: 'ca_sx',
            key: 'ca_sx',
            align: 'center',
            width: 100,
        },
        {
            title: 'Xưởng',
            dataIndex: 'xuong',
            key: 'xuong',
            align: 'center',
            width: 100,
        },
        {
            title: 'Công đoạn',
            dataIndex: 'cong_doan',
            key: 'cong_doan',
            align: 'center',
            width: 150,
        },
        {
            title: 'Máy sản xuất',
            dataIndex: 'machine',
            key: 'machine',
            align: 'center',
            width: 150,
        },
        {
            title: 'Mã máy',
            dataIndex: 'machine_id',
            key: 'machine_id',
            align: 'center',
            width: 100,
        },
        {
            title: 'Khách hàng',
            dataIndex: 'khach_hang',
            key: 'khach_hang',
            align: 'center',
            width: 150,
        },
        {
            title: 'Mã hàng',
            dataIndex: 'product_id',
            key: 'product_id',
            align: 'center',
            width: 120,
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'ten_san_pham',
            key: 'ten_san_pham',
            align: 'center',
            width: 100,
        },
        {
            title: 'Lô SX',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center',
            width: 100,
        },
        {
            title: 'Mã thùng/pallet',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center',
            width: 150,
        },
        {
            title: 'SL đầu ra thực tế',
            dataIndex: 'sl_dau_ra_hang_loat',
            key: 'sl_dau_ra_hang_loat',
            align: 'center',
            width: 150,
        },
        {
            title: 'Số lượng đầu ra OK',
            dataIndex: 'sl_dau_ra_ok',
            key: 'sl_dau_ra_ok',
            align: 'center',
            width: 150,
        },
        {
            title: 'SL tem vàng',
            dataIndex: 'sl_tem_vang',
            key: 'sl_tem_vang',
            align: 'center',
            width: 100,
        },
        {
            title: 'SL NG (SX tự KT)',
            dataIndex: 'sl_ng_sxkt',
            key: 'sl_ng_sxkt',
            align: 'center',
            width: 120,
        },
        {
            title: 'Công nhân SX',
            dataIndex: 'user_sxkt',
            key: 'user_sxkt',
            align: 'center',
            width: 100,
        },
        {
            title: 'SL NG (PQC)',
            dataIndex: 'sl_ng_pqc',
            key: 'sl_ng_pqc',
            align: 'center',
            width: 100,
        },
        {
            title: 'PQC kiểm tra',
            dataIndex: 'user_pqc',
            key: 'user_pqc',
            align: 'center',
            width: 120,
        },
        {
            title: 'Số lượng NG',
            dataIndex: 'sl_ng',
            key: 'sl_ng',
            align: 'center',
            width: 100,
            render: (value1, record, index) => {
                value1 = parseInt(value1);
                //   let lot_id = record['lot_id'];
                return <>
                    <Button style={{ backgroundColor: 'red', color: 'white' }}
                        onClick={() => value1 > 0 && openDetailQC(record)}>
                        {value1}
                    </Button>

                </>
            }
        },
        {
            title: 'Tỉ lệ NG',
            dataIndex: 'ti_le_ng',
            key: 'ti_le_ng',
            align: 'center',
            width: 100,
        },
    ];

    const [chartLoading, setChartLoading] = useState(false);
    const loadChartData = async (params) => {
        setChartLoading(true);
        var res = await getQualittyDataChart(params);
        setErrorTrending(res.data?.errorTrending ?? []);
        setMaterialErrorRatioData(res.data?.materialErrorRatioData ?? []);
        setErrorRatio(res.data.errorRatioData ?? [])
        setChartLoading(false);
    }
    const loadDataTable = async (params) => {
        setLoading(true)
        const res1 = await getQualityDataTable(params);
        setTotalPage(res1.data.totalPage);
        setDataTable2(res1.data.data);
        setLoading(false);
    }
    function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadDataTable({ ...params, page, pageSize })
        loadChartData({ ...params, page, pageSize })
    }
    useEffect(() => {
        btn_click()
    }, [])
    const [exportLoading, setExportLoading] = useState(false);
    const [selectedLot, setSelectedLot] = useState();
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportQualittyDataTable(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }

    const [loading, setLoading] = useState(false);
    const columnDetailError = [
        {
            title: 'Tên lỗi',
            key: 'error_name',
            dataIndex: 'error_name',
            align: 'center'
        },
        {
            title: 'Số lượng',
            key: 'value',
            dataIndex: 'value',
            align: 'center'
        }
    ];
    const [dataDetailError, setDataDetailError] = useState([]);
    const [exportLoading1, setExportLoading1] = useState(false);
    const exportFileDetail = async () => {
        setExportLoading1(true);
        const res = await exportTestCriteriaHistory(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading1(false);
    }
    const [isModalBCOpen, setIsModalBCOpen] = useState(false);
    const showModalBC = () => {
        setIsModalBCOpen(true);
    };
    const closeModalBC = () => {
        setIsModalBCOpen(false);
    };
    const [formExportReport] = Form.useForm();
    const [exportLoading2, setExportLoading2] = useState(false);
    const exportReport = async (values) => {
        setExportLoading2(true);
        const res = await exportPQCReport(values);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading2(false);
    }
    const onCheck = (checkedKeys, info) => {
        const selectedLineKeys = [];
        const selectedMachineKeys = [];
        if (info.node.type === "factory") {
            if (info.checked) {
                info.node.children.map((e) => {
                    selectedLineKeys.push(e.key?.toString());
                });
            }
        } else {
            info.checkedNodes.map((e) => {
                switch (e.type) {
                    case "line":
                        selectedLineKeys.push(e.key?.toString());
                        break;
                    case "machine":
                        selectedMachineKeys.push(e.key?.toString());
                        break;
                    default:
                        break;
                }
            });
        }
        setParams({ ...params, line_id: [...new Set(selectedLineKeys)], machine_code: selectedMachineKeys });
    };
    const [dateType, setDateType] = useState('date');
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    const [errorTrendingData, setErrorTrending] = useState([]);
    const [materialErrorRatioData, setMaterialErrorRatioData] = useState([]);
    const [errorRatio, setErrorRatio] = useState([]);
    return (
        <React.Fragment>
            <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
                <Col span={4} className='custom-col'>
                    <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                        <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                    ]}>
                        <Form style={{ margin: '0 8px' }} layout="vertical">
                            <Divider>Công đoạn</Divider>
                            <Form.Item className='mb-3'>
                                {
                                    listLines.length ?
                                        <Tree
                                            defaultExpandedKeys={[2]}
                                            style={{ maxHeight: 250, overflowY: 'auto' }}
                                            checkable
                                            selectable={false}
                                            onCheck={onCheck}
                                            treeData={listLines}
                                        />
                                        :
                                        <LoadingOutlined />
                                }
                            </Form.Item>
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                            </Space>
                            <Divider>Điều kiện truy vấn</Divider>
                            <Form.Item label="Khách hàng" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn khách hàng"
                                    onChange={(value) => { setParams({ ...params, khach_hang: value }); }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                                    options={listCustomers}
                                />
                            </Form.Item>
                            <Form.Item label="Tên sản phẩm" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    onChange={(value) => {
                                        setParams({ ...params, ten_sp: value });
                                    }}
                                    placeholder="Nhập tên sản phẩm"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listNameProducts}
                                />
                            </Form.Item>
                            <Form.Item label="Lô Sản xuất" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Nhập lô sản xuất"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(value) => {
                                        setParams({ ...params, lo_sx: value });
                                    }}
                                    options={listLoSX}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>

                <Col span={20} className="custom-col">
                    <Spin spinning={chartLoading}>
                        <Row gutter={[8, 8]} style={{ marginBottom: '8px' }}>
                            <Col span={18}>
                                <ErrorTrending data={errorTrendingData} />
                            </Col>
                            <Col span={6}>
                                <MaterialErrorRatio data={materialErrorRatioData} />
                            </Col>
                            <Col span={24}>
                                <ErrorRatio data={errorRatio} />
                            </Col>
                        </Row>
                    </Spin>
                    <Card title="Bảng chi tiết lỗi" style={{ padding: '0px' }} bodyStyle={{ padding: 12 }} extra={
                        <Space>
                            <Button type='primary' onClick={showModalBC}>Báo cáo</Button>
                            <Button type='primary' loading={exportLoading1} onClick={exportFileDetail}>Bảng kiểm tra</Button>
                            <Button type='primary' loading={exportLoading} onClick={exportFile}>Xuất excel</Button>
                        </Space>
                    }>
                        <Table
                            className="table-mh20"
                            bordered
                            loading={loading}
                            locale={{ emptyText: '' }}
                            size="small"
                            columns={columns2}
                            dataSource={dataTable2}
                            pagination={{
                                current: page,
                                size: 'default',
                                total: totalPage,
                                onChange: (page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                    loadDataTable({ ...params, page, pageSize })
                                }
                            }}
                            scroll={
                                {
                                    x: '120vw',
                                    y: '60vh'
                                }
                            } />
                    </Card>
                </Col>

            </Row>

            <Modal title="Chi tiết NG" open={isModalOpen} footer={null} onCancel={closeModal} width={600}>
                <Card styles={{ body: { padding: 0 } }}>
                    <Table bordered size="small" className="table-detail" scroll={{ x: '100%' }} columns={columnDetailError} dataSource={dataDetailError} pagination={false}></Table>
                    <PQCDetailChart data={dataDetailError} />
                </Card>
            </Modal>
            <Modal title="Báo cáo" open={isModalBCOpen} onCancel={closeModalBC}
                okText="Xuất báo cáo"
                cancelText="Huỷ"
                okButtonProps={{ loading: exportLoading2 }}
                onOk={() => formExportReport.submit()}>
                <Form layout="vertical" form={formExportReport} onFinish={exportReport}
                    initialValues={{
                        day: dayjs(),
                        week: dayjs(),
                        month: dayjs(),
                        year: dayjs(),
                    }}>
                    <Form.Item label={'Ngày'} name={'day'} rules={[{ required: true }]}>
                        <DatePicker style={{ width: '100%' }}></DatePicker>
                    </Form.Item>
                    <Form.Item label={'Tuần'} name={'week'} rules={[{ required: true }]}>
                        <DatePicker picker="week" style={{ width: '100%' }}></DatePicker>
                    </Form.Item>
                    <Form.Item label={'Tháng'} name={'month'} rules={[{ required: true }]}>
                        <DatePicker picker="month" style={{ width: '100%' }}></DatePicker>
                    </Form.Item>
                    <Form.Item label={'Năm'} name={'year'} rules={[{ required: true }]}>
                        <DatePicker picker="year" style={{ width: '100%' }}></DatePicker>
                    </Form.Item>

                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default QualityPQC;
