import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Layout, Table, Row, Card, Button, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./THSX.css";
import { getProductionSituationByMachine } from "../../api/phase2/dashboard/main";
import CalculateTableHeight from "../../components/calculateTableHeight";
import echo from "../../helpers/echo";
import SketchfabCNCModel from "./Sketchfab/SketchfabCNCModel";
import logolight from "../../assets/images/logo.png";
import { Header } from "antd/es/layout/layout";
import SketchFab from "./Sketchfab/SketchFab";

const colTable = [
  {
    title: "MÁY",
    dataIndex: "machine_code",
    key: "machine_code",
    align: "center",
  },
  {
    title: "TÊN SẢN PHẨM",
    dataIndex: "product",
    key: "product",
    align: "center",
  },
  {
    title: "SL KẾ HOẠCH",
    dataIndex: "sl_dau_ra_kh",
    key: "sl_dau_ra_kh",
    align: "center",
  },
  {
    title: "MỤC TIÊU",
    dataIndex: "sl_muc_tieu",
    key: "sl_muc_tieu",
    align: "center",
  },
  {
    title: "SL THỰC TẾ",
    dataIndex: "sl_thuc_te",
    key: "sl_thuc_te",
    align: "center",
  },
  {
    title: "TỈ LỆ HOÀN THÀNH (%)",
    dataIndex: "ti_le_ht",
    key: "ti_le_ht",
    align: "center",
  },
  {
    title: "TỈ LỆ NG (%)",
    dataIndex: "ti_le_ng",
    key: "ti_le_ng",
    align: "center",
  },
  {
    title: "TÌNH TRẠNG",
    dataIndex: "status",
    key: "status",
    render: (value) => {
      var color = "white";
      if (value == "1") color = "orange";
      else if (value == "2") color = "blue";
      else if (value == "3") color = "green";
      else color = "#929292";
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div
            style={{
              width: "70px",
              height: "70px",
              background: color,
              borderRadius: "50%",
            }}
          ></div>
        </div>
      );
    },
    align: "center",
  },
];

const ProductionSituation2 = () => {
  const [clock, setClock] = useState(new Date());
  const dataRef = useRef([]);
  const history = useHistory();
  const [tableHeight, setTableHeight] = useState("100%");
  const [isShow3D, setIsShow3D] = useState("none");
  const [isShowTable, setIsShowTable] = useState("block");
  const noteItems = [
    { title: "Máy dừng", color: "orange" },
    { title: "Máy đang chạy", color: "green" },
    { title: "Máy hoàn thành kế hoạch", color: "blue" },
  ];
  const loadData = async () => {
    const res1 = await getProductionSituationByMachine({
      ordering_machine: (dataRef.current ?? [])
        .map((e) => e.machine_code)
        .join(","),
    });
    setDataTable(
      res1.data.map((e, index) => {
        if (index === (res1.data ?? [])?.length - 1) {
          setSpecifiedRowKey(index);
        }
        return { ...e, key: index };
      })
    );
    return res1;
  };
  useEffect(() => {
    setInterval(() => tick(), 1000);
  }, []);
  const tick = () => {
    setClock(new Date());
  };
  useEffect(() => {
    let isCancelled = false;
    let timeout;
    const fetchData = async () => {
      try {
        // Gọi API
        await loadData();
        if (!isCancelled) {
          timeout = setTimeout(fetchData, 3000); // Gọi lại sau 3 giây
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Xóa interval khi component bị huỷ
    return () => {
      isCancelled = true;
      clearTimeout(timeout);
    };
  }, []);
  const [dataTable, setDataTable] = useState([]);
  useEffect(() => {
    console.log(dataTable.length, dataRef.current);
    if (dataTable.length != dataRef.current?.length) {
      setTableHeight(CalculateTableHeight("table-db"));
    }
    dataRef.current = dataTable;
  }, [dataTable]);
  useEffect(() => {
    echo.connector.socket.on("connect", () => {
      console.log("WebSocket connected!");
    });
    echo.connector.socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });
    echo.connector.socket.on("disconnect", () => {
      console.log("WebSocket disconnected!");
    });
    echo
      .channel("laravel_database_monitor-channel")
      .listen(".monitor-updated", (e) => {
        console.log("Event received:", e);
        // setIsShow3D("flex");
        // setIsShowTable("none");
      });
    return () => {
      echo.leaveChannel("laravel_database_monitor-channel");
    };
  }, []);

  const tableRef = useRef();
  const [specifiedRowKey, setSpecifiedRowKey] = useState(null);
  const handleScrollToRow = () => {
    if (specifiedRowKey !== null && tableRef.current) {
      tableRef.current?.scrollTo({ key: specifiedRowKey });
    }
  };
  const layoutStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh-70px)",
    position: "relative", // Add this line
  };
  useEffect(() => {
    if (dataTable.length > 0) {
      handleScrollToRow();
    }
  }, [specifiedRowKey, dataTable]);

  return (
    <React.Fragment>
      <Layout
        style={{
          backgroundColor: "#e3eaf0",
          overflowX: "hidden",
          display: isShowTable,
        }}
      >
        <Row
          className="w-100"
          style={{
            height: 80,
            verticalAlign: "center",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            backgroundColor: "#2462a3",
            color: "white",
          }}
        >
          <div
            style={{
              fontSize: "1.6em",
              fontWeight: "700",
              width: "15%",
              textAlign: "center",
            }}
          >
            {clock
              .toLocaleString(["en-GB"], { hour12: false })
              .replace(",", "")}
          </div>
          <div style={{ fontWeight: 700, fontSize: "2em" }}>
            TÌNH HÌNH SẢN XUẤT
          </div>
          <Link
            to={"/screen"}
            style={{ margin: "auto 0", width: "15%", textAlign: "right" }}
          >
            <CloseOutlined
              className="text-white"
              style={{ fontSize: "1.4em" }}
            />
          </Link>
        </Row>
        <Card
          title={null}
          styles={{
            body: {
              padding: 0,
              height: "100%",
              backgroundColor: "transparent",
            },
          }}
          style={{
            height: "calc(100vh - 80px - 32px)",
            margin: 16,
            backgroundColor: "transparent",
          }}
        >
          <Table
            className="table-db"
            bordered
            pagination={false}
            scroll={{ y: CalculateTableHeight("table-db") - 100 }}
            columns={colTable}
            dataSource={dataTable}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "5px",
              height: "100px",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            {noteItems.map((item, index) => (
              <div
                key={index}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    backgroundColor: "whitesmoke",
                    padding: "0 30px",
                  }}
                >
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      background: item.color,
                      borderRadius: "50%",
                      border: "1px solid gray",
                    }}
                  ></div>
                  <div style={{ fontWeight: "bold", fontSize: "24px" }}>
                    {item.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </Layout>
      {/* z */}
    </React.Fragment>
  );
};

export default ProductionSituation2;
